* {
  font-family: 'Roboto', sans-serif;
}

/* .App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

/* 
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #61dafb;
}

/* header {
  background-color: #282c34;
  padding: 16px !important;
  padding-top: 24px !important;
} */

.footer-container {
  padding-top: 1rem;
  background: #d3d3d3;
}

main {
  padding-top: 16px;
  padding-left: 22px !important;
}

form {
  /* area where body proper shown */
  padding-left: 14px;
}

.content {
  padding-left: 2px;
}

*/

/* h2 {margin-left: -8px;} */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.main-body {
  min-height: 600px;
}

.site-logo {
  height: 85px;
  width: 198px;
  display: inline-block;
  background-image: url("../assets/img/logo.png");
  background-repeat: no-repeat;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

*/